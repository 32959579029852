import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export const GET_SESSION_DETAILS = "getsessiondetails";
export const SET_INITIAL_INSTALL_INFO = "setInitialInstallInfo";
export const SET_INSTALL_INFO = "setInstallInfo";
export const SET_INSTALL_INFO_ERROR = "setInstallInfoError";
export const SET_SINGLE_INSTALL_INFO_ERROR = "setSingleInstallInfoError";

export const store = new Vuex.Store({
  state: {
    // common
    currentModal: "",
    accessToken: null,
    sessionDetails: null,
    allPurposeToastObject: null,
    envVariables: null,
    currentUser: {
      federationIdentifier: "coshea@salesforce.com",
    },

    // search data
    recentlyViewedObjects: null,
    qSearchAuthToken: null,
    allSearchedRecords: null,
    searchedComponents: null,
    searchedUsers: null,
    searchedBundles: null,
    searchObj: {
      searchCategory: "All", // All, Users, Bundles, Components
      options: ["All", "Users", "Bundles", "Components"],
      searchTerm: "",

      componentsSearchLimit: 8,
      bundlesSearchLimit: 4,
      usersSearchLimit: 5,
    },

    // component arrays
    installedByYouComponents: null,
    favoriteComponents: null,
    ownedComponents: null,
    allComponents: null,
    recommendedComponents: null,
    mostFavoritedComponents: null,
    mostInstalledComponents: null,
    recentlyUsedComponents: null,

    // bundles
    featuredBundles: null,

    listFilterValues: {
      sortBy: "Trending",
      clouds: "",
      features: "",
      industries: "",
      stars: null,
    },
    paginationSettings: {
      pagesSizeOptions: [8, 16, 32, 64],
      currentPageSizeOption: 8,
      totalDemoComponentCount: 1,
      currentPage: 1,
      totalPages: 1,
    },

    searchValues: {
      sortBy: "Trending",
      clouds: "",
      features: "",
      industries: "",
      stars: null,
    },

    stockpileOrgs: null,
    authSource: null,
    demoOrgPackages: null,
    selectedOrgAuthDetails: null,
    selectedOrgPackage: null,
    selectOrgSpinner: false,

    componentsToInstall: null,
    installResults: null,

    // currentDeploymentId: null,
    // deploymentResults: null
  },
  getters: {
    sessionDetails: (state) => {
      return state.sessionDetails;
    },
    getCurrentModal: (state) => {
      return state.currentModal;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    getRecentlyViewedObjects: (state) => {
      return state.recentlyViewedObjects;
    },
    getInstalledByYouComponents: (state) => {
      return state.installedByYouComponents;
    },
    getFavoriteComponents: (state) => {
      return state.favoriteComponents;
    },
    getAllComponents: (state) => {
      return state.allComponents;
    },
    getListFilterValues: (state) => {
      return state.listFilterValues;
    },
    getOwnedComponents: (state) => {
      return state.ownedComponents;
    },
    getRecommendedComponents: (state) => {
      return state.recommendedComponents;
    },
    getMostFavoritedComponents: (state) => {
      return state.mostFavoritedComponents;
    },
    getMostInstalledComponents: (state) => {
      return state.mostInstalledComponents;
    },
    getSearchValues: (state) => {
      return state.searchValues;
    },
    getPaginationSettings: (state) => {
      return state.paginationSettings;
    },
    getAllPurposeToastObject: (state) => {
      return state.allPurposeToastObject;
    },
    getFeaturedBundles: (state) => {
      return state.featuredBundles;
    },
    getQSearchAuthToken: (state) => {
      return state.qSearchAuthToken;
    },
    getSearchObj: (state) => {
      return state.searchObj;
    },
    getAllSearchedRecords: (state) => {
      return state.allSearchedRecords;
    },
    getSearchedComponents: (state) => {
      return state.searchedComponents;
    },
    getSearchedUsers: (state) => {
      return state.searchedUsers;
    },
    getSearchedBundles: (state) => {
      return state.searchedBundles;
    },
    getEnvVariables: (state) => {
      return state.envVariables;
    },

    getStockpileOrgs: (state) => {
      return state.stockpileOrgs;
    },
    getAuthSource: (state) => {
      return state.authSource;
    },
    getDemoOrgPackages: (state) => {
      return state.demoOrgPackages;
    },
    getSelectOrgSpinner: (state) => {
      return state.selectOrgSpinner;
    },
    getSelectedOrgAuthDetails: (state) => {
      return state.selectedOrgAuthDetails;
    },
    getSelectedOrgPackage: (state) => {
      return state.selectedOrgPackage;
    },

    getComponentsToInstall: (state) => {
      return state.componentsToInstall;
    },
    // getCurrentDeploymentId: state => { return state.currentDeploymentId },
    // getDeploymentResults: state => { return state.deploymentResults },

    getInstallResults: (state) => {
      return state.installResults;
    },
    getRecentlyUsedComponents: (state) => {
      return state.recentlyUsedComponents;
    },
  },
  mutations: {
    setCurrentModal: (state, payload) => {
      state.currentModal = payload;
    },
    setAccessToken: (state, payload) => {
      state.accessToken = payload;
    },
    setSessionDetails: (state, payload) => {
      state.sessionDetails = payload;
    },
    setCurrentUser: (state, payload) => {
      state.currentUser = payload;
    },
    setRecentlyViewedObjects: (state, payload) => {
      state.recentlyViewedObjects = payload;
    },
    setInstalledByYouComponents: (state, payload) => {
      state.installedByYouComponents = payload;
    },
    setFavoriteComponents: (state, payload) => {
      state.favoriteComponents = payload;
    },
    setAllComponents: (state, payload) => {
      state.allComponents = payload;
    },
    setListFilterValues: (state, payload) => {
      state.listFilterValues = payload;
    },
    setOwnedComponents: (state, payload) => {
      state.ownedComponents = payload;
    },
    setRecommendedComponents: (state, payload) => {
      state.recommendedComponents = payload;
    },
    setMostFavoritedComponents: (state, payload) => {
      state.mostFavoritedComponents = payload;
    },
    setMostInstalledComponents: (state, payload) => {
      state.mostInstalledComponents = payload;
    },
    setSearchValues: (state, payload) => {
      state.searchValues = payload;
    },
    setPaginationSettings: (state, payload) => {
      state.paginationSettings = payload;
    },
    setAllPurposeToastObject: (state, payload) => {
      state.allPurposeToastObject = payload;
    },
    setFeaturedBundles: (state, payload) => {
      state.featuredBundles = payload;
    },
    setQSearchAuthToken: (state, payload) => {
      state.qSearchAuthToken = payload;
    },
    setSearchObj: (state, payload) => {
      state.searchObj = payload;
    },
    setAllSearchedRecords: (state, payload) => {
      state.allSearchedRecords = payload;
    },
    setSearchedComponents: (state, payload) => {
      state.searchedComponents = payload;
    },
    setSearchedUsers: (state, payload) => {
      state.searchedUsers = payload;
    },
    setSearchedBundles: (state, payload) => {
      state.searchedBundles = payload;
    },
    setEnvVariables: (state, payload) => {
      state.envVariables = payload;
    },

    setStockpileOrgs: (state, payload) => {
      state.stockpileOrgs = payload;
    },
    setAuthSource: (state, payload) => {
      state.authSource = payload;
    },
    setDemoOrgPackages: (state, payload) => {
      state.demoOrgPackages = payload;
    },
    setSelectOrgSpinner: (state, payload) => {
      state.selectOrgSpinner = payload;
    },
    setSelectedOrgAuthDetails: (state, payload) => {
      state.selectedOrgAuthDetails = payload;
    },
    setSelectedOrgPackage: (state, payload) => {
      state.selectedOrgPackage = payload;
    },

    setComponentsToInstall: (state, payload) => {
      state.componentsToInstall = payload;
    },
    // setCurrentDeploymentId: (state, payload) => { state.currentDeploymentId = payload },
    // setDeploymentResults: (state, payload) => { state.deploymentResults = payload },

    setInstallResults: (state, payload) => {
      state.installResults = payload;
    },
    setRecentlyUsedComponents: (state, payload) => {
      state.recentlyUsedComponents = payload;
    },
  },
  actions: {
    printStore({ commit, state }) {
      console.log("STATE : ", state);
    },
    setFavouriteAndInstalledForComponents({ commit, state }) {},
    [GET_SESSION_DETAILS](context, { router }) {
      console.log("GET_SESSION_DETAILS called");
      var url = new URL(window.location.href);
      var userid = url.searchParams.get("userid");
      var orgid = url.searchParams.get("orgid");
      var handoff_param;
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf("handoff_param=") == 0) {
          handoff_param = c.substring("handoff_param=".length, c.length);
        }
      }
      console.log(handoff_param + " " + userid + " " + orgid);
      if (userid != null && orgid != null && handoff_param == null) {
        if (userid == "USER_NOT_FOUND" && orgid == "USER_NOT_FOUND") {
          router.push("/user-not-found");
          context.commit("setSessionDetails", null);
        } else {
          return new Promise((resolve, reject) => {
            axios
              .get("/auth/getdata/" + encodeURIComponent(userid) + "/" + encodeURIComponent(orgid))
              .then((res) => {
                if (!res.data.error) {
                  console.log(res.data);
                  context.commit("setSessionDetails", res.data);
                  resolve(res.data);
                } else {
                  if (res.data.status_code == 403) {
                    context.commit("setSessionDetails", res.data);
                    resolve(res.data);
                  } else {
                    console.log("Error :: getAccessToken :: " + res.data.error);
                    reject(res.data.error);
                  }
                }
              })
              .catch((err) => {
                console.log("Exception :: getAccessToken :: " + err);
                reject(err);
              });
          });
        }
      } else if (userid == null && orgid == null && handoff_param != null) {
        return new Promise((resolve, reject) => {
          axios
            .get("/auth/aloha_getdata/" + encodeURIComponent(handoff_param))
            .then((res) => {
              if (!res.data.error) {
                console.log(res.data);
                context.commit("setSessionDetails", res.data);
                resolve(res.data);
              } else {
                if (res.data.status_code == 403) {
                  context.commit("setSessionDetails", res.data);
                  resolve(res.data);
                } else {
                  console.log("Error :: getAccessToken :: " + res.data.error);
                  reject(res.data.error);
                }
              }
            })
            .catch((err) => {
              console.log("Exception :: getAccessToken :: " + err);
              reject(err);
            });
        });
      } else {
        var path = encodeURIComponent(
          window.location.href
            .replace(window.location.hostname, "")
            .replace("http://", "")
            .replace("https://", "")
            .replace("/#", "")
            .replaceAll("/", "~")
        );
        console.log(path);

        // HEROKU STAGING
        // var url = "https://q-passport.sfdc.sh/auth/alohaauth?app=demo-components-aloha-staging&additional_params=%7B%22redirect_path%22%3A%22"+path+"%22%7D";

        // HEROKU PRODUCTION
        var url =
          "https://q-passport.sfdc.sh/auth/okta?app=demo-components&additional_params=%7B%22redirect_path%22%3A%22" +
          path +
          "%22%7D";

        console.log("test: ", url);
        window.open(url, "_self");
      }
    },
    [SET_INITIAL_INSTALL_INFO](context, { deploy_id }) {
      console.log("SET_INITIAL_INSTALL_INFO :: " + deploy_id);
      var installResults = context.getters.getInstallResults;
      for (var i = 0; i < installResults.length; i++) {
        //console.log("installResults[i].demoComponent.package_url__c: ", installResults[i].demoComponent.package_url__c);
        //console.log("installResults[i].deployId: ", installResults[i].deployId);
        //This is causing issues
        // var fixedRepoName = installResults[i].demoComponent.package_url__c.replace(
        //   "https://github.com/sfdc-qbranch/",
        //   ""
        // );
        // if (deploy_id.includes(fixedRepoName)) {
        //   installResults[i].deployId = deploy_id;
        // }
        installResults[i].deployId = deploy_id;
      }
      context.commit("setInstallResults", installResults);
    },
    [SET_INSTALL_INFO](context, { deploy_results }) {
      console.log("SET_INSTALL_INFO :: ", deploy_results);
      var installResults = context.getters.getInstallResults;
      for (var i = 0; i < installResults.length; i++) {
        if (deploy_results.deployId == installResults[i].deployId) {
          installResults[i].deployJobStatus = deploy_results;
        }
      }
      context.commit("setInstallResults", installResults);
    },
    [SET_INSTALL_INFO_ERROR](context, { deploy_results, component_id }) {
      console.log("SET_INSTALL_INFO_ERROR :: ", deploy_results);
      var installResults = context.getters.getInstallResults;
      for (var i = 0; i < installResults.length; i++) {
        if (component_id == installResults[i].demoComponent.sfid) {
          installResults[i]["deployId"] = null;
          installResults[i].deployJobStatus = deploy_results;
        }
      }
      context.commit("setInstallResults", installResults);
    },
    [SET_SINGLE_INSTALL_INFO_ERROR](context, { deploy_results }) {
      // console.log('SET_SINGLE_INSTALL_INFO_ERROR :: ' , deploy_results);
      // var installResults = context.getters.getInstallResults;
      // for(var i = 0; i < installResults.length; i++) {
      //     if(deploy_results.demoComponent.sfid == installResults[i].demoComponent.sfid) {
      //         installResults[i].deployJobStatus = {
      //         }
      //     }
      // }
      // context.commit('setInstallResults', installResults);
    },
  },
});
